import React, { useState, useEffect } from "react";
import './home.css';
import homeBanner from '../../assets/btv-main-banner.png';

const Home = () => {
    const [timeLeft, setTimeLeft] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    });

    useEffect(() => {
        // Event date - October 19
        const eventDate = new Date('October 19, 2024 00:00:00').getTime();

        const updateCountdown = () => {
            const now = new Date().getTime();
            const distance = eventDate - now;

            // Calculate time left
            const days = Math.floor(distance / (1000 * 60 * 60 * 24));
            const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((distance % (1000 * 60)) / 1000);

            setTimeLeft({ days, hours, minutes, seconds });
        };

        // Initial call to set countdown
        updateCountdown();

        // Update the countdown every second
        const interval = setInterval(updateCountdown, 1000);

        // Cleanup the interval on component unmount
        return () => clearInterval(interval);
    }, []);

    return (
        <>
            <section className="home-section">
                <div className="home-banner">
                    <img src={homeBanner} alt="beyond the vote event dates" />
                </div>
                <div className="countdown">
                    <h2>The Countdown Has Begun!</h2>
                    <div className="countdown-timer">
                        <div className="countdown-item">
                            <span >{timeLeft.days}</span>
                            <span >Days</span>
                        </div>
                        <span className="colon">:</span>
                        <div className="countdown-item">
                            <span >{timeLeft.hours}</span>
                            <span >Hours</span>
                        </div>
                        <span className="colon">:</span>
                        <div className="countdown-item">
                            <span >{timeLeft.minutes}</span>
                            <span >Minutes</span>
                        </div>
                        <span className="colon">:</span>
                        <div className="countdown-item">
                            <span >{timeLeft.seconds}</span>
                            <span >Seconds</span>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Home;
