import './App.css';
import Home from './components/home/Home';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <>
      <Router basename='/'>
        <Routes>
          <Route path='/' element={<><Home /></>}>
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
